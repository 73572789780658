<template>
  <div
    class="absloute grid md:grid-cols-3 grid-cols-2 gap-4 bottom-0 items-center h-24 px-16 mt-8"
    dir="rtl"
  >
    <div class="md:grid grid-cols-3 gap-1 h-full content-center hidden">
      <div class="mt-2">
        <router-link to="/">
          <img
            src="@/assets/footerLogo.webp"
            class="xsm:h-4 h-5 md:h-7 mr-1"
            alt="Nasan Logo"
            width="100"
            height="100"
          />
        </router-link>
      </div>
    </div>

    <div
      class="grid grid-rows-3 gap-0 justify-items-start md:hidden"
      dir="rtl"
    ></div>
    <div
      class="hidden md:flex justify-center space-x-4 text-md font-bold sm:-ml-4"
    >
      <div class="ml-4">
        <a href="https://sites.google.com/view/nasna-tv" class="text-gray-300">الشروط والاحكام</a>
      </div>

      <div class="ml-4">
        <a href="https://sites.google.com/view/nasnatv" class="text-gray-300">سياسة الاستخدام</a>
      </div>
      <div class="ml-4">
        <a href="https://m.me/nasnaTv.SD" class="text-gray-300">اتصل بنا</a>
      </div>
    </div>

    <div class="hidden md:block sm:ml-4">
      <div class="flex justify-center space-x-4 float-left">
        <div class="grid grid-cols-3 gap-4 content-center mr-28">
          <SvgView />
        </div>
      </div>
    </div>
    <div class="hidden md:block col-span-2 mt-16 space-x-16">
      <!-- <hr class="m-2 bg-gray-700 w-full " style=""> -->
      <small class="text-gray-200 text-center text-sm" style="margin-right: 68%"
        >جميع الحقوق محفوظة ©   ناسنا 2023</small
      >
    </div>
  </div>
</template>

<script>
import SvgView from './SvgView.vue'

export default {
  components: { SvgView }
}
</script>

<style></style>
